<template>
    <div>
        <h3 class="mt-3 mb-base">Invited Users</h3>
        <vs-row>
            <vs-col vs-xs="12" vs-sm="10" vs-lg="10" class="px-0 pr-2 mb-base">
                <vs-input
                    class="w-full"
                    placeholder="Type to Search"
                    v-model="filters.search"
                />
            </vs-col>
            <vs-col vs-xs="12" vs-sm="2" vs-lg="2" class="flex mb-base p-0">
                <vx-tooltip text="Refresh Data" color="primary" class="ml-4">
                    <vs-button color="primary" type="filled" class="w-full" @click="init()" :disabled="loading">
                        <font-awesome-icon :icon="redoIcon" />
                    </vs-button>
                </vx-tooltip>
                <vx-tooltip text="Invite User" color="primary" class="ml-4">
                    <router-link :to="{name: 'user-invite'}">
                        <vs-button color="primary" type="filled" class="w-full" :disabled="loading">
                            <font-awesome-icon :icon="addIcon" />
                        </vs-button>
                    </router-link>
                </vx-tooltip>
            </vs-col>
        </vs-row>
		<div class="vx-col w-full mb-base">
            <vx-card>
                <vs-table :data="results" slot="no-body" :hoverFlat="true" stripe>
                    <vs-tr>
                        <vs-th>Email</vs-th>
                        <vs-th>Role</vs-th>
                        <vs-th>Status</vs-th>
                        <vs-th>Invited By</vs-th>
                        <vs-th>Action</vs-th>
                    </vs-tr>
                    <template>
                        <template v-for="(item, index) in results">
                            <vs-tr :key="index">
                                <vs-td>
                                    {{ item.email }}
                                </vs-td>
                                <vs-td>
                                    <FormatText :text="item.role" format="select" :kwargs="role_options" />
                                </vs-td>
                                <vs-td>
                                    <FormatText :text="item.status" format="select" :kwargs="status_options" />
                                </vs-td>
                                <vs-td>
                                    {{ (item.invited_by.first_name || '') + ' ' + (item.invited_by.last_name || '') }}
                                </vs-td>
                                <vs-td>
                                    <template v-if="item.status == 'p'">
                                        <vs-button @click="_do_delete(index)" type="filled" size="small" color="primary">Revoke</vs-button>
                                    </template>
                                    <template v-else>
                                        -
                                    </template>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </template>
                </vs-table>
            </vx-card>
        </div>
        <template v-if="!disable_pagination">
            <vs-row v-if="total_results" class="mt-3 text-center">
                <vs-pagination :total="total_pages" v-model="current_page" goto class="mx-auto"></vs-pagination>
            </vs-row>
        </template>
    </div>
</template>

<script>
import { faPlus, faRedo } from '@fortawesome/free-solid-svg-icons';
import {Request} from "../../utils/requests"

export default {
	props: {
        embedded_mode: {
            required: false,
            default: false
        },
        embedded_filters: {
            required: false,
            default: () => {}
        },
        disable_pagination: {
            required: false,
            default: false
        },
        global_mode: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        let offset = parseInt(this.$route.query.offset || 0);
        let limit = parseInt(this.$route.query.limit || 20);

        let current_page = Math.ceil((offset + limit) / limit);
        return  {
            results: [],

			next_url: null,
            loading: false,
            total_results: null,
            current_page: current_page,
            filters: {
                search: this.$route.query.search || null,
                ordering: this.$route.query.ordering || [],
                offset: offset,
                limit: limit
            },

            submit_disabled: false,
            api_errors: {},
            role_options: [
                {label: 'Admin', value: 'a'},
                {label: 'Staff', value: 's'},
                {label: 'Client', value: 'c'},
            ],

            status_options: [
                {label: 'Pending', value: 'p'},
                {label: 'Accepted', value: 'a'},
                {label: 'Rejected', value: 'r'},
            ]
        }
    },
    mounted() {
        this.init()
    },
	computed: {
        total_pages() {
            if(this.total_results == 0 || this.filters.limit == 0)  return 1
            return Math.ceil(this.total_results/this.filters.limit)
        },
        redoIcon: () => faRedo,
        addIcon: () => faPlus
    },
    watch: {
        filters: {
            deep: true,
            handler(newV) {
                if (this.current_page == null) return;

                if (this.timer) clearTimeout(this.timer);

                this.timer = setTimeout(() => {
                    this.$router.push({ name: this.$route.name, query: {...newV, ...this.embedded_query_params} });
                }, 800);
            }
        },
        "$route.query"(newV) {
            this.load_data(newV);
        },
        current_page(newV, oldV) {
            if (newV == oldV) return;
            this.filters.offset = (newV - 1) * this.filters.limit;
        }
    },
    methods: {
        init(){
            this.load_data(this.filters)
        },
        calculate_page() {
            if (this.filters.offset == 0) this.current_page = 1;
            else
                this.current_page = Math.ceil(this.filters.offset / this.filters.limit);
        },
        load_data(filters=null) {
            this.loading = true;
            this.$vs.loading();

            let url = this.$store.state.apiendpoints.users_invited

            let payload = filters

            if (!payload) payload = {};

            if (this.embedded_mode && this.embedded_filters) {
                payload = { ...payload, ...this.embedded_filters };
            }

            if (this.global_mode) {
                payload['global'] = true
            }

            this.api_errors = {}

            Request(this, 'GET', url, payload).then( 
                (res) => {
                    this.results = res.results
                    this.total_results = res.count
                    this.next_url = res.next;
                    this.loading = false;
                    this.$vs.loading.close();
                }, 
                (err) => {
                    this.loading = false;
                    this.$vs.loading.close();
                    if (err.error) {
                        this.api_errors = err.error

                        this.$vs.notify({
                            time: 2500,
                            title: 'Error',
                            text: "Please fix the errors and try again!",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    } else {
                        this.$vs.notify({
                            time: 2500,
                            title: 'Error',
                            text: "Unable to load Organisations Users. Please try again later.",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                }
            )
        },
        _do_delete(index) {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: 'Are you sure?',
                text: `Are you sure you want to revoke invitation for this user?`,
                accept: () => this.delete(index),
                'accept-text': 'Yes',
                'cancel-text': 'No'
            })
        },
        delete(index) {
            this.loading = true;
            this.$vs.loading();

            let url = this.$store.state.apiendpoints.users_invited + this.results[index].id + '/'

            Request(this, 'DELETE', url).then(
                (res) => {
                    this.loading = false;
                    this.$vs.loading.close();
                    this.results.splice(index, 1)

                    this.$vs.notify({
                        time: 3000,
                        title: 'Success',
                        text: "User invitation revoke operation is performed successfully.",
                        iconPack: 'feather',
                        icon: 'icon-check-circle',
                        color: 'success'
                    })
                },
                (err) => {
                    this.loading = false;
                    this.$vs.loading.close();
                    if (err.error) {
                        this.api_errors = err.error
                        this.$vs.notify({
                            time: 2500,
                            title: 'Error',
                            text: (err.error && err.error.__non_field_errors__) ? err.error.__non_field_errors__.join(',') : 'An Error Occured. Please Try Again.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    } else {
                        this.$vs.notify({
                            time: 2500,
                            title: 'Error',
                            text: "Please try again later.",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                }
            )
        }
    }
}
</script>